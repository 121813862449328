<template>
	<v-row>
		<v-col cols="12" lg="6" md="12" v-for="(announcement, index) in announcements" :key="index" class="d-flex flex-column">
			<FeedAnnouncement :announcement="announcement" />
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'FeedAnnouncements',
	components: {
		FeedAnnouncement: () => import('@/components/feed/FeedAnnouncement.vue'),
	},
	props: {
		announcements: {
			type: Array,
			required: true,
		},
	},
}
</script>
